import * as React from 'react';

import Page from '../components/Page';
import Footer from '../components/Footer';
import FrequentlyAskedQuestions from '../templates/misc/FrequentlyAskedQuestions';
import Header from '../components/Header';

function FAQsPage({ location }: { location: Location }) {
  return (
    <Page location={location} header={<Header search={false} />}>
      <FrequentlyAskedQuestions />
      <Footer />
    </Page>
  );
}

export default FAQsPage;
