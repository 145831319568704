import React, { ReactElement } from 'react';
import cx from 'classnames';

import Container from '../../components/Container';
import Link from '../../components/Link';

interface Group {
  title: string;
  questions: Question[];
}

type Question = [string, ReactElement];

const faqs: Group[] = [
  {
    title: 'Product',
    questions: [
      [
        'What is Notifee?',
        <>
          <p>
            Notifee is a library which brings the power of native notifications to React Native.
          </p>
          <p>
            The library integrates with the latest native Android & iOS SDKs to provide rich
            notification features in a single, easy to use JavaScript API.
          </p>
          <p>
            The library is a successor to the notifications module on{' '}
            <Link to="https://rnfirebase.io/">React Native Firebase v5</Link>.
          </p>
        </>,
      ],
      [
        'Who is the library built by?',
        <p>
          Notifee is built & maintained by <Link to="https://invertase.io">Invertase</Link>. Please
          see our website or <Link to="https://github.com/invertase">GitHub organisation</Link> for
          more information.
        </p>,
      ],
      [
        'Is Notifee an Open Source Product?',
        <>
          <p>Yes.</p>
          <p>
            Notifee is FOSS and available on{' '}
            <Link to="https://github.com/invertase/notifee">GitHub</Link>. This Open Source code is
            licensed under the{' '}
            <Link to="https://www.apache.org/licenses/LICENSE-2.0">Apache License 2.0</Link>.
          </p>
        </>,
      ],
      [
        'Can I use Notifee for free?',
        <>
          <p>Notifee is free to use and fully open source.</p>
        </>,
      ],
    ],
  },
];

function FrequentlyAskedQuestions() {
  // const [search, setSearch] = useState<string>('');
  // filtered = search;
  //
  // let filtered: Group[] = [];
  // console.log(faqs);
  // if (!search || search.length < 3) {
  //   filtered = faqs;
  // } else {
  //   faqs.forEach((group) => {
  //     let groupHasMatched = false;
  //     const matchedQuestions = [];
  //
  //     group.questions.forEach(([question, answer]) => {
  //       if (question.toLowerCase().includes(search.toLowerCase())) {
  //         groupHasMatched = true;
  //         return;
  //       }
  //
  //       if (typeof answer === 's')
  //     });
  //   })

  // TODO filter results
  // }
  return (
    <Container size="xs">
      <div className="pt-24">
        <h1 className="text-center text-5xl">Frequently Asked Questions</h1>
        {faqs.map(({ title, questions }) => (
          <div className="mt-16" key={title}>
            <h2 className="text-3xl font-semibold">{title}</h2>
            <div className="mt-12 pb-6">
              {questions.map(([question, answer], i) => (
                <div
                  key={question}
                  className={cx('pb-6 mt-6', {
                    'border-b': i + 1 !== questions.length,
                  })}
                >
                  <h3 className="text-2xl font-thin">{question}</h3>
                  <>{answer}</>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </Container>
  );
}

export default FrequentlyAskedQuestions;
